<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Driver Management" :detailID="DriverDetails.employee_no"/>
        <v-btn class="ma-1 mt-2" outlined color="primary" icon tile x-small>
          <v-icon small @click="()=>$router.push(`/drivers/${DriverDetails.id}/details`)">mdi-text-box</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mx-1 px-8" depressed dark color="primary" v-show="savingState">
            <v-progress-circular
              :size="20"
              color="white"
              indeterminate
              class="mr-3"
            ></v-progress-circular>Saving
        </v-btn>
        <v-btn 
          v-if="canDo && canDo.can.includes('update')"
          class="mx-1 px-8" depressed dark color="primary" v-show="!savingState" @click="saveDriver">Save</v-btn>
        <v-btn 
          v-if="canDo && canDo.can.includes('update')"
          class="mx-1 px-8" depressed dark color="primary" v-show="!savingState" @click="syncDriverStatus">Sync Status</v-btn>
        <v-btn class="mx-1 px-8" depressed dark color="grey" @click="()=>$router.push('/drivers')">Back</v-btn>
      </v-toolbar>  
      <v-divider></v-divider>
      <!-- Content -->
      <div class="content-wrap">
          <!-- <v-container> -->
            <v-card
              class="mx-auto d-flex pa-2 px-10 justify-center"
              max-width="90%"
              flat
            >
            <v-form v-model="form_valid" ref="form">
              <v-row>
                <v-col cols="3">
                  <ProfileImage/>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.name" label="Full name *" :rules="[validate_rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.email" :rules="[validate_rules.email]" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.phone" label="Phone No. *" :rules="[validate_rules.phone]" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.idcard" label="ID Card No. *" :rules="[validate_rules.idcard]" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-combobox
                        :items="allProvinces"
                        label="Province"
                        v-model="driver.province"
                        dense
                        
                        clearable
                    ></v-combobox>
                    <!-- @change="fetchZones()" -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field dense v-model="driver.selected_site" :rules="[]" label="District"></v-text-field>
                      <!--
                      <v-combobox
                        :items="allZones"
                        label="District *"
                        :search-input.sync="selectedZone"
                        :rules="[validate_rules.required]"
                        v-model="driver.selected_site"
                        item-value="value"
                        item-text="text"
                        @click="ifProvSelected()"
                        dense
                        clearable
                    ></v-combobox>
                    -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <v-text-field disabled v-model="driver.step" label="Step"></v-text-field> -->
                      <v-select
                        dense
                        :items="stepOptions"
                        label="Step"
                        item-value="id"
                        item-text="name"
                        v-model="driver.step"
                        disabled>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <v-text-field v-model="driver.tier" label="Tier" required></v-text-field> -->
                      <v-select
                        dense
                        :items="allTiers"
                        :disabled="!this.canDo || !this.canDo.can.includes('update_store_tier')"
                        label="Tier"
                        item-value="id"
                        item-text="name"
                        :rules="[validate_rules.select]"
                        v-model="driver.tier">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field disabled dense v-model="driver.loginext_id" label="Loginext ID"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field disabled dense v-model="driver.loginext_username" label="Loginext Username"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field disabled dense v-model="driver.zeek_ref_id" label="Zeek Partner ID"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field disabled dense v-model="driver.humanica_employee_no" label="Humanica Employee No"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field disabled dense v-model="driver.humanica_sync_date" label="Humanica Synced At"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <!-- <v-text-field v-model="driver.status" label="Status"></v-text-field> -->
                      <v-select
                        :items="statusOptions"
                        label="Status"
                        :rules="[validate_rules.select]"
                        v-model="driver.status">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        :items="depositStatusOptions"
                        label="Deposit Status *"
                        v-model="driver.deposit_status"
                        item-value="id"
                        item-text="text"
                        :rules="[validate_rules.select]"
                        >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        :items="rb_stores"
                        :disabled="!this.canDo ||!this.canDo.can.includes('update_store_tier')"
                        label="Store"
                        v-model="driver.store_uid"
                        item-value="store_uid"
                        :item-text="storeName"
                        :rules="[validate_rules.select]"
                        >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="categoriesArray"
                        v-model="driver.categories"
                        attach
                        dense
                        :rules="[validate_rules.required]"
                        small-chips
                        deletable-chips
                        label="Category Tags"
                        hide-selected
                        multiple
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <!--
                    <v-col cols="12" sm="12">
                      <p>Equipment</p>
                      <v-container class="grey lighten-5">
                        <v-row>
                          <v-col col="4" sm="4" class="pt-0" v-for="eq in equipmentList" :key="eq.id">
                            <v-checkbox
                              v-model="driver.equipments"
                              :label="eq.text"
                              :value="eq.id"
                              hide-details
                              class="ma-0"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    -->
                  </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                  <small class="red--text">* indicates required field</small>
                  </v-col>
                </v-row>
                </v-col>
              </v-row>
            </v-form>
            </v-card>
            <v-card
              class="mx-auto d-flex py-3 px-10 justify-center"
              max-width="90%"
              flat
            >
              <Evidences :driverid="DriverDetails.id"/>
            </v-card>
          <!-- </v-container> -->
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import ProfileImage from '@/components/drivers/profile_image.vue';
import Evidences from '@/components/drivers/evidences.vue';
import lodash from 'lodash';

export default {
  name: 'DriverEdit',
  data: () => ({
      driver: [],
      rb_stores: [],
      statusOptions: [
        { value: 1 , text : 'Active'},
        { value: 2 , text : 'Inactive'},
      ],
      stepOptions : [
        // { id: 0, name : 'Any...'},
        // { id: 1, name : 'Lead'},
        // { id: 2, name : 'Lead Rejected'},
        // { id: 3, name : 'Lead Approved'},
        // { id: 4, name : 'Onboard Rejected'},
        // { id: 5, name : 'LN Registering'},
        { id: 6, name : 'Onboard'},
        { id: 7, name : '1 Trip'},
        { id: 8, name : '10 Trips'},
        { id: 9, name : 'Driver'},
      ],
      form_valid : false,
      validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        select: v => { return (v !== '') || 'This field is required' },
        phone: v => (/^\d{10}$/.test(v) || 'Required 10 digits phone number'),
        idcard: v => (/[a-zA-Z0-9]{10,13}/.test(v) || 'Data pattern is invalid'),
        email: v => { 
          if(v) { 
            return (/.+@.+\..+/.test(v) || 'Valid email pattern is required');
          } else {
            return true
          } 
        },
      }
  }),
  components: {
      pageHeading,
      ProfileImage,
      Evidences
  },
  methods: {
    ...mapActions(['genCan','resetProfileImage','getDriverDetails','updateDriver','getProvinces','getZones','clearZones',
    'getTiers','getEquipments','getCategories', 'getUserProfile', 'getAllRedbookStore', 'getTPCTierId', 'syncDriver']),
    storeName: item => item.store_alias_id + " " + item.name,
    initialize() {
      this.driver = {
        id: this.DriverDetails.id,
        name : this.DriverDetails.name_th,
        email : this.DriverDetails.email,
        phone : this.DriverDetails.phone,
        idcard : this.DriverDetails.ssn,
        province: null,
        zone: this.DriverDetails.site_id,
        selected_site: this.DriverDetails.site_name,
        //selected_site : {
        //  value: this.DriverDetails.site_id,
        //  text: this.DriverDetails.site_name,
        //},
        store_uid : this.DriverDetails.store_uid,
        step: this.DriverDetails.employee_approve_step.id,
        tier : this.DriverDetails.employee_tier.id,
        status : this.DriverDetails.employee_status.id,
        loginext_id : this.DriverDetails.loginext_id,
        loginext_username : this.DriverDetails.loginext_username,
        categories: this.DriverDetails.employee_category_tag,
        deposit_status: this.DriverDetails.deposit_status,
        equipments: this.DriverDetails.equipments,
        humanica_sync_date: this.DriverDetails.humanica_sync_date,
        humanica_employee_no: this.DriverDetails.humanica_employee_no,
        zeek_ref_id: this.DriverDetails.zeek_ref_id,
      };
      if(this.DriverDetails.site_id){
        this.driver.province = {
          value: this.DriverDetails.site_id.substr(0,2),
          text: this.ProvincesArray[this.DriverDetails.site_id.substr(0,2)]
        };
        //this.getZones(this.driver.province.value);
      }
    },
    async saveDriver(){
      if(this.form_valid){

        if(this.driver.tier == (await this.getTPCTierId()) && !this.driver.store_uid)
        { 
          alert('กรุณาระบุค่า Store');
          return;
        }
        console.log("this.driver", this.driver);
        this.updateDriver(this.driver);
      } else {
        alert('กรุณากรอกข้อมูลให้ถูกต้อง')
      }
    },
    async syncDriverStatus(){
      if(confirm("กรุณาบันทึกข้อมูลที่มีการแก้ไขก่อน")){
        this.syncDriver(this.driver);
      } else {
        alert('กรุณากรอกข้อมูลให้ถูกต้อง')
      }
    },
    ifProvSelected(){
      if(!this.driver.province)
        alert('Please select a province filter');
    },
    fetchZones() {
      console.log(this.driver.province);
      this.driver.zone = null;
      if(this.driver.province){
        try {
        this.getZones(this.driver.province.value);
        } catch (e)
        {
          console.log("Error getZone ", e)
        }
      } else {
        this.clearZones();
      }
    }
  },
  computed: {
    ...mapGetters(['canDo','savingState','DriverDetails','ProvincesArray','allProvinces','allZones','allTiers',
    'depositStatus','equipmentList','categoriesArray', 'userProfile']),
    selectedZone: {
        get: function () {
          return this.driver.zone ? this.driver.zone.text : null;
        },
        set: function () {
          return this.driver.zone ? this.driver.zone.text : null;
        }
    },
    selectedStep() {
            var step = {};
            if(this.DriverDetails.employee_approve_step)
              step = this.DriverDetails.employee_approve_step.id;
            return step;
    },
    depositStatusOptions(){
      if(this.DriverDetails && this.DriverDetails.deposit_status == 0){
        return this.depositStatus.filter(element => (element.id != 2));
      } else {
        return this.depositStatus;
      }
    }
  },
  watch :{
    DriverDetails: function(){
      this.initialize();
    },
  },
  created() {
    this.genCan();
    this.resetProfileImage();
    this.getDriverDetails(this.$route.params.id);
    this.getProvinces();
    this.getTiers();
    this.getEquipments();
    if(!this.categoriesArray){
      this.getCategories();
    }
    this.getEquipments();
    if (this.userProfile.store_uid)
    {
      this.rb_stores = lodash.filter(this.rb_stores, function (item) {
        return this.userProfile.store_uid.includes(item.store_uid);
      })
    }
  },
  async beforeMount() {
    this.rb_stores = await this.getAllRedbookStore();
  },
};
</script>